// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

/**
 * Custom SASS
 */
 .container-img-album {
    background: #DDD;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 0;
    margin-bottom:15px;
    @include media-query($on-laptop) {
    	margin-right:-15px;
    	margin-left:-15px;
    }
    &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 65.4%;
    }
    &::after {
        content: "";
        clear: both;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: var(--background);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
        filter: blur(8px) saturate(0.7) brightness(0.8);
        transform: scale(1.05);
        z-index: -1;
    }
}
.img-album {
    max-height: 95vh;
    min-height: 491px;
    @include media-query($on-laptop) {
        min-height: unset;
    }
}

.language-ssh-public-key {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
 }
 